import clsx from "clsx";
import { ReactNode } from "react";

export type SummaryProps = {
  children: ReactNode;
};

const Summary = ({ children }: SummaryProps): JSX.Element => {
  return (
    <div className={clsx("px-4")}>
      <div className={clsx("font-body text-sm font-normal text-gray-600")}>
        {children}
      </div>
    </div>
  );
};

export default Summary;
